import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
const Post = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  background: #efefef;
`;
const Image = styled(Img)`
  min-height: 250px;
  display: block;
  width: 100%;
  height: auto;
  max-height: 400px;
  max-width: 1100px;
  margin: 0 auto;

  @media (min-width: 768px) {
    min-height: 400px;
    max-height: 400px;
    max-width: 800px;
  }
`;
const Title = styled.h1`
  @media (min-width: 768px) {
    width: 700px;
    margin: 1em auto;
    padding: 0;
  }
  padding: 0 0.5em;
  margin: 1em 0 0 0;
  box-sizing: border-box;
  color: #3f6564;
`;
const Content = styled.div`
  width: 100%;
  padding: 1em;
  margin-bottom: 3em !important;
  color: rgba(0, 0, 0, 67) !important;
  line-height: 1.8;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.05em;
  & figcaption {
    opacity: 0.6;
    margin: 0.4em 0.6em;
  }
  @media (min-width: 768px) {
    max-width: 740px;
    margin: 0 auto;
  }
  & p,
  & ul {
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: 1em;
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: rgba(0, 0, 0, 0.8) !important;
  }
`;
const TopContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    width: 800px;
    margin: 0 auto;
  }
`;
const PostDate = styled.h5`
  margin-top: -3em;
  background: #efefef;
  position: absolute;
  display: block;
  padding: 1em;
  z-index: 2;
  width: auto;
  max-width: 100%;
  color: #3f6564;
`;

export const pageQuery = graphql`
  query PostQuery($strapiId: Int!) {
    strapiBloggs(strapiId: { eq: $strapiId }) {
      date(formatString: "Do MMMM YYYY", locale: "nb-NO")
      Title
      Description
      Content
      Image {
        childImageSharp {
          resize(width: 800, height: 450) {
            src
          }
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

function BlogPost(props) {
  const post = props.data.strapiBloggs;
  const link = props.slug;

  return (
    <Layout
      title={post.Title}
      url={link}
      isblog
      description={post.Description}
      image={post.Image.childImageSharp.resize.src}
      date={post.date}
    >
      <Post>
        <Image fadeIn fluid={post.Image.childImageSharp.fluid} alt={Title} />
        <TopContent>
          <PostDate>{post.date}</PostDate>
          <Title>{post.Title}</Title>
        </TopContent>

        <Content>
          <ReactMarkdown source={post.Content} />
        </Content>
      </Post>
    </Layout>
  );
}

export default BlogPost;
